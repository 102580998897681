import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  themeFontWeight,
  themeColor,
  Button,
  Copy,
} from '@elparking/components'

const SocialButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0;
  font-weight: ${themeFontWeight('book')};
  background: ${({ background }) => themeColor(background)};
  color: ${({ color }) => themeColor(color)};
  border: 1px solid ${({ color }) => themeColor(color)};
  &:hover {
    color: ${({ color }) => themeColor(color)};
    background: ${({ hoverBackground }) => themeColor(hoverBackground)};
  }
  &:disabled {
    opacity: 0.4;
    background: ${({ background }) => themeColor(background)};
  }
  &:hover:disabled {
    border: 1px solid ${({ color }) => themeColor(color)};
  }
`

const SocialButtonLabel = styled.span`
  margin-left: 10px;
`

const SocialButtonGoogle = ({ href, ...props }) => (
    <SocialButtonContainer
      as='a'
      href={href}
      type='button'
      color='gray'
      border='gray'
      background='white'
      hoverBackground='lightGray40'
      width='100%'
      {...props}
    >
        <Copy as='span' width='16px' h='16px'>
            <svg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
                <clipPath id='a'>
                    <path d='M44.666 19.668h-20.5v8.5h11.8c-1.1 5.4-5.7 8.5-11.8 8.5-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4c-3.9-3.4-8.9-5.5-14.5-5.5-12.2 0-22 9.8-22 22s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z' />
                </clipPath>
                <path clipPath='url(#a)' fill='#FBBC05' d='M0 37V11l17 13z' />
                <path
                  clipPath='url(#a)'
                  fill='#EA4335'
                  d='M0 11l17 13 7-6.1L48 14V0H0z'
                />
                <path
                  clipPath='url(#a)'
                  fill='#34A853'
                  d='M0 37l30-23 7.9 1L48 0v48H0z'
                />
                <path
                  clipPath='url(#a)'
                  fill='#4285F4'
                  d='M48.083 47.834l-31-24-4-3 35-10z'
                />
            </svg>
        </Copy>
        <SocialButtonLabel>Google</SocialButtonLabel>
    </SocialButtonContainer>
)

SocialButtonGoogle.propTypes = {
  href: PropTypes.string,
}

export default SocialButtonGoogle
