import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Control } from 'commons/js/ui/Input'
import { required as requiredValidator, length, validCharacter } from 'commons/js/util/validators'
import messages from './messages'

const FirstNameField = ({ intl, required, validators, errorMessages, disabled, ...restProps }) => (
    <Control
      model='.firstName'
      validators={{
          ...(required && { required: requiredValidator() }),
          length: length({min: 1, max: 50}),
          validCharacter: validCharacter(),
          ...validators,
      }}
      placeholder={intl.formatMessage(messages.placeholder)}
      autoComplete='off'
      errorMessages={{
          required: intl.formatMessage(messages.requiredError),
          length: intl.formatMessage(messages.lengthError),
          validCharacter: intl.formatMessage(messages.invalidCharacters),
          ...errorMessages,
      }}
      debounce={250}
      disabled={disabled}
      showClearButton={!disabled}
      backgroundColor={disabled ? 'lightGray20' : 'transparent'}
      {...restProps}
    />
)

FirstNameField.propTypes = {
    intl: PropTypes.object,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    validators: PropTypes.object,
    errorMessages: PropTypes.object,
}

FirstNameField.defaultProps = {
    disabled: false,
    required: true,
}

export default injectIntl(FirstNameField)
