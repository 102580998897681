import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Copy, Box, ClockSpinnerLoader, Image, BrowserWrapper, Message } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { TRADENAME } from 'commons/js/constants'

const Iframe = styled.iframe`
  width: 100%;
  transition: opacity 2s, height 0.5s;
  overflow: hidden;
  opacity: ${(opacity) => opacity};
`

const LoginForm = ({
  loginUrl,
  logo,
  height,
  loading,
  error,
  locale,
  simple,
  authenticated,
  registerSuccessMessage,
}) => {
  return <Box>
      {logo && <BrowserWrapper>
          <Box textAlign='center' mb='2em'>
              {logo}
          </Box>
      </BrowserWrapper>
      }
      <Box minHeight={height || '250px'}>
          {registerSuccessMessage && <Message.success title={ <FormattedMessage
            defaultMessage='¡Bienvenido a {tradename}! Te hemos enviado un correo para validar tu cuenta.'
            values={{
                      tradename: TRADENAME,
                    }}
          />}/>}
          {error && <Box textAlign='center' mt='20px'>
              <Box textAlign='center'>
                  <Image src='/images/login/error.svg' alt='App' />
              </Box>
              <Copy fontWeight='book' fontSize='large' padding='40px 20px' fontColor='gray' textAlign='center'>
                  <FormattedMessage defaultMessage='Se ha producido un error. Inténtalo de nuevo más tarde.' />
              </Copy>
          </Box>}
          {!error && (loading || !height || authenticated || !loginUrl) && <Flex height='250px' width='100%' justifyContent='center' alignItems='center'>
              <ClockSpinnerLoader size='60px' color={'gray'}/>
          </Flex>}
          {!error && !loading && !!loginUrl && !authenticated && <Iframe
            name={document.location.origin}
            loading='lazy'
            height={height || '0px'}
            scrolling='no'
            opacity={height ? '1' : '0'}
            src={`${loginUrl}&kc_locale=${locale}&simple=${simple}`} />}
      </Box>
  </Box>
}

LoginForm.propTypes = {
  error: PropTypes.bool,
  simple: PropTypes.bool,
  authenticated: PropTypes.bool,
  loading: PropTypes.bool,
  logo: PropTypes.node,
  height: PropTypes.string,
  loginUrl: PropTypes.string,
  locale: PropTypes.string,
  registerSuccessMessage: PropTypes.bool,
}

LoginForm.defaultProps = {
  simple: false,
  logo: null,
  error: false,
  loading: false,
  authenticated: false,
  loginUrl: null,
  height: null,
  registerSuccessMessage: false,
}

export default LoginForm
