import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Control } from 'commons/js/ui/Input'
import { required as requiredValidator, email, length } from 'commons/js/util/validators'

import messages from './messages'

const EmailField = ({ intl, required, ...restProps }) => (
    <Control
      model='.email'
      type='email'
      validators={{
          email: email(),
          length: length({ min: 1, max: 100 }),
          ...(required && { required: requiredValidator() }),
      }}
      placeholder={intl.formatMessage(messages.placeholder)}
      autoComplete='email'
      errorMessages={{
        ...(required && { required: intl.formatMessage(messages.requiredError) }),
          email: intl.formatMessage(messages.emailError),
          length: intl.formatMessage(messages.lengthError),
      }}
      debounce={250}
      {...restProps}
  />
)

EmailField.propTypes = {
  intl: PropTypes.object,
  required: PropTypes.bool,
}

EmailField.defaultProps = {
  required: true,
}

export default injectIntl(EmailField)
