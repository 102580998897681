import React from 'react'
import { Flex, Box, HorizontalRule, Copy } from '@elparking/components'
import GoogleSocialButton from './GoogleSocialButton'
import FacebookSocialButton from './FacebookSocialButton'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

const SocialButtons = ({ hrefFacebook, hrefGoogle }) => {
    return (
        <>
            <Flex w='100%' alignItems='center' mb='8px'>
                <Box flex='1'>
                    <HorizontalRule />
                </Box>
                <Box px='8px'>
                    <Copy padding='0' fontWeight='book' size='xSmall' color='gray60'>
                        <FormattedMessage defaultMessage='Accede rápidamente con' />
                    </Copy>
                </Box>
                <Box flex='1'>
                    <HorizontalRule />
                </Box>
            </Flex>
            <Flex w='100%' justifyContent='space-around' alignItems='center'>
                <Box flex='1' mr='8px'>
                    <FacebookSocialButton href={hrefFacebook} />
                </Box>
                <Box flex='1' ml='8px'>
                    <GoogleSocialButton href={hrefGoogle} />
                </Box>
            </Flex>
        </>
    )
}

SocialButtons.propTypes = {
  hrefFacebook: PropTypes.string,
  hrefGoogle: PropTypes.string,
}

export default SocialButtons
