import { defineMessages } from 'react-intl'

export default defineMessages({
    placeholder: {
        defaultMessage: 'Email',
    },
    requiredError: {
        defaultMessage: 'Obligatorio',
    },
    emailError: {
        defaultMessage: 'No es un email válido',
    },
    lengthError: {
        defaultMessage: 'Debe contener como máximo 100 letras',
    },
})
