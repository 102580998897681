import { defineMessages } from 'react-intl'

export default defineMessages({
    placeholder: {
        defaultMessage: 'Apellidos',
    },
    requiredError: {
        defaultMessage: 'Obligatorio',
    },
    lengthError: {
        defaultMessage: 'Debe contener como máximo 50 letras.',
    },
    invalidCharacters: {
        defaultMessage: 'Hay caracteres no permitidos',
    },
})
