import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { height } from 'commons/js/theme/styles'
import { APPLICATION, TRADENAME } from 'commons/js/constants'

export const StyledLogo = styled.img`
    ${height}
`

StyledLogo.propType = {
    type: 'svg',
    height: 'auto',
}

const Logo = ({ type, color, height }) => {
    const srcColor = color ? `_${color}` : ''
    const application = APPLICATION.toLowerCase().replace(/\s/g, '')

    let sufix = application !== 'elparking' ? '_' + application : ''
    const src = `/images/logo_header${sufix}${srcColor}.${type}`
    return <StyledLogo
      src={src}
      height={height}
      alt={TRADENAME} />
}

Logo.defaultProps = {
    type: 'svg',
    height: 'auto',
}

Logo.propTypes = {
    height: PropTypes.string.isRequired,
    color: PropTypes.string,
    type: PropTypes.oneOf(['svg', 'png']),
}

export default Logo
