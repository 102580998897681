import Routes from '../routes/routes'
import { buildSessionUser } from './converters/user'
import { buildPostApi } from './helpers'

function mapPayload ({
    code,
    redirectUri,
 }) {
    return {
        code,
        redirectUri,
    }
}

function buildResult (user) {
    return buildSessionUser(user)
}

export default buildPostApi({
    route: Routes.loginKeycloak,
    mapPayload,
    buildResult,
})
