import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Control } from 'commons/js/ui/Input'
import messages from './messages'
import { required as requiredValidator, length, validCharacter } from 'commons/js/util/validators'

const LastNameField = ({ intl, required, disabled, ...restProps }) => (
    <Control
      model='.lastName'
      validators={{
        length: length({ min: 1, max: 50 }),
        validCharacter: validCharacter(),
        ...(required && { required: requiredValidator() }),
      }}
      placeholder={intl.formatMessage(messages.placeholder)}
      autoComplete='off'
      errorMessages={{
        length: intl.formatMessage(messages.lengthError),
        validCharacter: intl.formatMessage(messages.invalidCharacters),
        ...(required && { required: intl.formatMessage(messages.requiredError) }),
      }}
      debounce={250}
      disabled={disabled}
      showClearButton={!disabled}
      backgroundColor={disabled ? 'lightGray20' : 'transparent'}
      {...restProps}
  />
)

LastNameField.propTypes = {
  intl: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
}

LastNameField.defaultProps = {
  required: false,
  disabled: false,
}

export default injectIntl(LastNameField)
