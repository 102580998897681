import React from 'react'
import PropTypes from 'prop-types'
import { Box, Copy } from '@elparking/components'
import { injectIntl, FormattedMessage } from 'react-intl'
import { getConstant } from 'commons/js/constants'
import HighlightedBlock from 'commons/js/ui/HighlightedBlock'

const CountryWebLoginError = ({logo}) => {
  return <Box>
      {logo && <Box textAlign='center' mb='2em'>
              {logo}
          </Box>}
      <HighlightedBlock color='warning' background='warning20' padding='12px'>
          <Copy fontWeight='regular' padding='0' fontColor='gray'>
              <FormattedMessage defaultMessage='Descárgate la APP de {tradename} para usar tu cuenta' values={{ tradename: getConstant('TRADENAME') }} />
          </Copy>
      </HighlightedBlock>
  </Box>
}

CountryWebLoginError.propTypes = {
    logo: PropTypes.node,
}

export default injectIntl(CountryWebLoginError)
